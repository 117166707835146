import { useState, useEffect, useRef, useCallback } from 'react'
import {
  Form,
  FormInstance,
  Row,
  Col,
  Button,
  Card,
  Radio,
  Input,
  InputNumber,
} from 'antd'
import useForm from '@hooks/useForm'
import type { IOrder, IItemOrderRequest, IItem } from '@/types/IOrder'
import { SearchSelect } from '@components/User/Form'
import { CloseOutlined, CopyOutlined } from '@ant-design/icons'
import { api } from '@helpers/api'
import { useTranslation } from 'react-i18next'
import FormItemsOrderRequest from '../FormItemsOrderRequest'
import { useGenericContext } from '@hooks/useGenericContext'
import { OrderContext } from '@contexts/OrderContext'
import FormUser from '@components/Company/FormUser'
import SelectDate from '../SelectDate'
import { removeNullValues } from '@helpers/removeNullValues'
import useTableContext from '@hooks/useTableContext'
import { FormOrderProps, SearchSelectCRMProps } from '@/types/IOrder'
import { calculateOrderPrice } from '@helpers/calculateOrderPrice'
import { debounce } from 'lodash'
const APP_URL = import.meta.env.VITE_OPEN_URL

const initialValues = (data: Partial<IOrder> | undefined) => {
  if (data) {
    return {
      ...data,
      customer: data?.customer?.uuid,
      opportunity: data?.opportunity?.uuid
    }
  }
}

const handleFinish = (values: IOrder, onFinish: (object: any) => void) => {
  const formattedValues = {
    ...removeNullValues(values),
    items: values.items.map(item => ({
      product: { uuid: item.product.uuid, device: item.product?.device },
      order_request: {
        "items": removeNullValues(
          item.order_request.items.map(formatOrderItem)
        )
      }
    }))
  }

  onFinish(formattedValues)
}

export const formatOrderItem = (orderItem: IItemOrderRequest) => {
  const orderItems = {
    uuid: orderItem.item.uuid,
    children: orderItem.children?.items?.map(item => ({
      uuid: item?.item?.uuid,
      current: item?.current ?? orderItem?.current
    }))
  }

  if (orderItem.item.type === 'BOOL') {
    if (orderItem.is_used === true)
      return { ...orderItems, current: 1, is_used: true }
    return
  }

  return {
    ...orderItems,
    current:
      orderItem.children?.items?.length > 0
        ? orderItem.children.items.length
        : orderItem.current
  }
}

const fetchProductData = () => {
  return api.get('/product')
    .then(product => {
      const products = product.data?.items ? product.data.items : product.data.data?.[0]?.items
      const productUuid = product.data.data[0].uuid
      const productDc = product.data.data[0].dc

      return { products, productUuid, productDc }
    })
}

const formatItems = (products: any[]) => {
  return products.map((item: IItem) => ({
    item: item,
    current: item.min
  }))
}

const setDefaultValues = (form: FormInstance, productUuid: string, productDc: any, formattedItems: any[], data?: Partial<IOrder>) => {
  const defaultValues = {
    items: [
      {
        product: {
          uuid: productUuid,
          dc: productDc
        },
        order_request: {
          items: formattedItems,
        }
      }
    ]
  }

  if (data) {
    const initialFormValues = initialValues(data)

    const mergedItems = data.items
      ? data.items.map(item => ({
        product: item.product,
        order_request: { items: item.order_request?.items || [] },
      }))
      : []

    form.setFieldsValue({
      ...initialFormValues,
      items: mergedItems,
    });
  } else {
    form.setFieldsValue(defaultValues)
  }
}

const FormOrder = ({ data }: FormOrderProps) => {
  const { refreshTable } = useTableContext()
  const formRef = useRef<FormInstance>(Form.useForm()[0])
  const form = formRef.current

  const { action, numForm, onClose, setPrice } = useGenericContext(OrderContext)
  const { t } = useTranslation()
  const { onFinish, disabled, contextHolder, responseData } = useForm(
    action,
    onClose,
    '/order',
    data?.uuid
  )

  useEffect(() => {
    if (responseData?.uuid) {
      refreshTable('/order?sort=-created_at')
    }
  }, [responseData, refreshTable])

  const debouncedCalculateOrderPrice = useCallback(
    debounce(values => {
      calculateOrderPrice(values, setPrice)
      form.validateFields(['items']).then(() => {
        const items = form.getFieldValue('items') || []
        calculateAllSubtotals(items, values)
      })
    }, 300),
    [form, setPrice]
  )

  const calculateAllSubtotals = async (items: any[], allValues: any) => {
    const newSubtotals = []
    for (let i = 0; i < items.length; i++) {
      const subtotal = await calculateSubtotal(items, i, allValues)
      newSubtotals[i] = subtotal
    }
    subtotalsRef.current = newSubtotals
    setSubtotals(newSubtotals)
  }

  const [subtotals, setSubtotals] = useState<number[]>([])
  const subtotalsRef = useRef<number[]>([])

  const calculateSubtotal = async (
    items: any[],
    index: number,
    allValues: any
  ): Promise<number> => {
    const product = items[index].product
    const formattedValues = {
      ...removeNullValues(allValues),
      items: [
        {
          product: { uuid: product.uuid, device: product?.device },
          order_request: {
            "items": removeNullValues(
              items[index].order_request?.items?.map(formatOrderItem)
            )
          }
        }
      ]
    }
    return api.post(`/order/calculate`, formattedValues)
      .then(response => response.data.price_total)
  }

  useEffect(() => {
    const fetchDataAndSetValues = async () => {
      fetchProductData()
        .then(productData => {
          if (productData) {
            const { products, productUuid, productDc } = productData
            const formattedItems = formatItems(products)

            setDefaultValues(form, productUuid, productDc, formattedItems, data)
            debouncedCalculateOrderPrice(form.getFieldsValue())
          }
        })
    }

    fetchDataAndSetValues()
  }, [data, debouncedCalculateOrderPrice, form])

  return (
    <Form
      {...{ form, disabled }}
      layout="vertical"
      initialValues={initialValues(data)}
      onFinish={values => handleFinish(values, onFinish)}
      id={numForm}
      onValuesChange={(_, allValues) => {
        debouncedCalculateOrderPrice(allValues)
      }}
    >
      {contextHolder}
      <Row gutter={[16, 16]}>
        <SearchSelectCRM data={data} name="customer" form={form} />
        <SearchSelectCRM data={data} name="opportunity" form={form} />
        <Col lg={8} xs={24}>
          <SelectDate />
        </Col>
        <Col span={24}>
          <FormUser
            action={action}
            data={data?.contract_signatures}
            nameForm="contract_signatures"
            title="SIGNATURE"
          />
        </Col>
        <Col span={24}>
          <span>{t('PRODUCTS')}</span>
          <FormItems
            form={form}
            subtotals={subtotals}
            setSubtotals={setSubtotals}
            calculateSubtotal={calculateSubtotal}
            allValues={form.getFieldsValue()}
            debouncedCalculateOrderPrice={debouncedCalculateOrderPrice}
          />
        </Col>
        <InputPercent />
      </Row>
    </Form>
  )
}

const SearchSelectCRM = ({ data, name, form }: SearchSelectCRMProps) => {
  const [searchUrl, setSearchUrl] = useState(`crm/${name}?`)

  const handleSearchUpdate = () => {
    const oppositeName = name === 'customer' ? 'opportunity' : 'customer'
    const oppositeValue = form.getFieldValue(oppositeName)

    const queryString = oppositeValue
      ? `${oppositeName}=${oppositeValue}&`
      : ''
    setSearchUrl(`crm/${name}?${queryString}`)
  }

  useEffect(() => {
    handleSearchUpdate()
  }, [name, data, form])

  return (
    <Col lg={8} xs={24}>
      <SearchSelect
        initialValue={
          data?.customer
            ? [{ value: data[name]?.uuid, label: data[name]?.name }]
            : []
        }
        require={true}
        name={name.toUpperCase()}
        searchUrl={searchUrl}
        onChange={() => handleSearchUpdate()}
      />
    </Col>
  )
}

const FormItems = ({
  form,
  subtotals,
  setSubtotals,
  calculateSubtotal,
  allValues,
  debouncedCalculateOrderPrice
}: {
  form: FormInstance
  subtotals: number[]
  setSubtotals: (subtotals: number[]) => void
  calculateSubtotal: (
    items: any[],
    index: number,
    allValues: any
  ) => Promise<number>
  allValues: any
  debouncedCalculateOrderPrice: (values: any) => void
}) => {
  const { t } = useTranslation()

  const createOrderItem = (item: IItem) => ({
    item: item,
    current: item.min,
  })

  const updateItem = (products: IItem[], item: any, index: number, key: number) => {
    if (index === key) {
      return {
        order_request: {
          items: products.map(createOrderItem),
        },
        product: item,
      }
    }
    return item
  }

  const handleOnChange = async (value: string, key: number) => {
    api.get(`/product/${value}`)
      .then(result => {
        const products = result.data?.items ? result.data.items : result.data.data[0].items
        const { items, ...product } = result.data
        const currentItems = form.getFieldValue('items') || []
        const updatedItems = currentItems.map((item, index) => updateItem(products, product, index, key))
        form.setFieldsValue({ items: updatedItems })
      })
  }

  const handleDuplicateItem = async (index: number) => {
    const items = form.getFieldValue('items') || []
    const itemToDuplicate = items[index]

    if (itemToDuplicate) {
      const duplicatedItem = JSON.parse(JSON.stringify(itemToDuplicate))
      const newItems = [...items, duplicatedItem]

      calculateSubtotal(
        newItems,
        newItems.length - 1,
        allValues
      ).then(newSubtotal => {
        form.setFieldsValue({ items: newItems })
        setSubtotals((prevSubtotals) => [...prevSubtotals, newSubtotal])
        debouncedCalculateOrderPrice(form.getFieldsValue())
      })
    }
  }

  const formatSubtotal = (subtotal: number | undefined): string => {
    if (subtotal === undefined) {
      return '0.00'
    }
    return subtotal.toLocaleString('en', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }

  return (
    <Form.List name="items">
      {(fields, { add, remove }) => (
        <>
          <Row gutter={[8, 8]} style={{ marginBottom: '10px' }}>
            {fields.map(({ key, name }) => {
              return (
                <Col xs={24} xl={12} key={key}>
                  <Card
                    style={{ width: '100%' }}
                    type="inner"
                    key={key}
                    size="small"
                    title={`${t('PRODUCT')} ${name + 1}`}
                    extra={
                      <>
                        <Button
                          type="text"
                          icon={<CopyOutlined />}
                          onClick={() => handleDuplicateItem(name)}
                          data-cy="CopyOutlined"
                        />
                        <CloseOutlined onClick={() => remove(name)} data-cy="RemoveProduct" />
                      </>
                    }
                  >
                    <Row>
                      <Col span={18} xl={18} xs={24}>
                        <SearchSelect
                          require={true}
                          name={[name, 'product', 'uuid']}
                          searchUrl="product?"
                          placeholder={t('PRODUCT')}
                          onChange={value => handleOnChange(value, name)}
                          data-cy={`select-product-${name}`}
                        />
                      </Col>
                      {form.getFieldValue(['items', name, 'product']) ? (
                        <>
                          <Col xl={{ span: 4, offset: 2 }} xs={24}>
                            <Form.Item
                              name={[name, 'datacenter']}
                              label={'Datacenter'}
                            >
                              <Radio.Group
                                optionType="button"
                                buttonStyle="solid"
                              >
                                <Radio>
                                  {(() => {
                                    const shortname = form.getFieldValue([
                                      'items',
                                      name,
                                      'product',
                                      'dc',
                                      'shortname'
                                    ]);
                                    const iconName = shortname ? shortname.substring(0, 3).toLowerCase() : '';
                                    return (
                                      <>
                                        <img
                                          src={`${APP_URL}/assets/images/dc/${iconName}.png`}
                                          alt="DC Icon"
                                          style={{
                                            width: '25px',
                                            height: '15px',
                                            marginRight: '8px',
                                            verticalAlign: 'middle'
                                          }}
                                        />
                                        {shortname}
                                      </>
                                    );
                                  })()}
                                </Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                          <Col span={18} xl={18} xs={24}>
                            {form.getFieldValue([
                              'items',
                              name,
                              'product',
                              'type',
                            ]) === 'BAREMETAL' && (
                                <SearchSelect
                                  require={true}
                                  name={[name, 'product', 'device']}
                                  searchUrl={`device?bmavailable=${form.getFieldValue([
                                    'items',
                                    name,
                                    'product',
                                    'dc',
                                    'uuid',
                                  ])}&`}
                                  placeholder={t('HOST')}
                                />
                              )}
                          </Col>
                        </>
                      ) : null}
                      <Col span={24}>
                        <FormItemsOrderRequest name={name} form={form} />
                      </Col>
                    </Row>
                    <Button
                      variant="filled"
                      style={{
                        pointerEvents: 'none',
                        textAlign: 'center',
                        color: '#389e0d',
                        backgroundColor: '#f6ffed',
                      }}
                      data-cy="subtotal"
                    >
                      SUBTOTAL: {formatSubtotal(subtotals[name])}
                    </Button>
                  </Card>
                </Col>
              )
            })}
          </Row>
          <Form.Item data-cy="add-items">
            <Button block type="primary" onClick={() => add()}>
              {t('ADD_PRODUCT')}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}

const InputPercent = () => {
  const { t } = useTranslation()

  return (
    <>
      <Col xs={24} lg={4}>
        <Form.Item name="discount_percents" label={t('DISCOUNT')}>
          <InputNumber addonAfter="%" min={0} max={100} />
        </Form.Item>
      </Col>
      <Col xs={24} lg={20}>
        <Form.Item name="discount_motive" label={t('DISCOUNT_MOTIVE')}>
          <Input.TextArea rows={2} autoSize={{ minRows: 2, maxRows: 4 }} />
        </Form.Item>
      </Col>
    </>
  )
}
export default FormOrder
