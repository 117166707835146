import { Card, Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'

function RankingCards() {
  const { t } = useTranslation()

  return (
    <Row gutter={[16, 16]}>
      {/*
      <Col span={8}>
        <a href='/tv/ranking/meetings'>
          <Card type='inner' hoverable title="Ranking de Agendamentos">Click para acessar o rank de agendamentos.</Card>
        </a>
      </Col>
    */}
      <Col span={8}>
        <a href='/tv/ranking/calls'>
          <Card type='inner' hoverable title={t('CALLSRANK')}>{t("CALLSDESCRIPTION")}</Card>
        </a>
      </Col>
    </Row>
  )
}

export default RankingCards
