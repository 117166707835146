import { useState } from 'react'
import {
  Form,
  InputNumber,
  Row,
  Col,
  Radio,
  Button,
  Select,
  RadioChangeEvent,
  Switch,
  Space,
  Tooltip
} from 'antd'
import { useTranslation } from 'react-i18next'
import type { OrderRequestProps, OrderItemProps, IItem } from '@/types/IOrder'
import IconButton from '@components/common/IconButton'
import Icon from '@components/common/Icon'

const FormItemsOrderRequest = ({ name, form }: OrderRequestProps) => {
  const data = form.getFieldsValue()
  return (
    <Form.List name={[name, 'order_request', 'items']}>
      {fields => {
        return (
          <>
            {fields.map(({ name: orderRequestKey }) => (
              <>
                {data?.items ? (
                  <OrderItem
                    orderRequestKey={orderRequestKey}
                    data={data.items[name].order_request.items[orderRequestKey]}
                  />
                ) : null}
              </>
            ))}
          </>
        )
      }}
    </Form.List>
  )
}

const OrderItem = ({ orderRequestKey, data }: OrderItemProps) => {
  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        {data.item.children?.length > 0 && (
          <ChildrenItem data={data} orderRequestKey={orderRequestKey} />
        )}

        {data.item.type === 'BOOL' && (
          <SwitchItem data={data} formName={[orderRequestKey, 'is_used']} />
        )}
        {data.item.type === 'ITEM' && (
          <SliderItem
            formName={[orderRequestKey, 'current']}
            data={data.item}
          />
        )}
      </Col>
    </Row>
  )
}

const SwitchItem = ({ formName, data }: any) => {
  return (
    <Col span={24}>
      <Form.Item
        name={formName}
        label={
          <Space>
            <Icon name={`fa-light ${data.item.icon_name}`} />
            <Tooltip title={data.item.description}>
              <span>{data.item.item}</span>
            </Tooltip>
          </Space>
        }
        required={data.required}
      >
        <Switch />
      </Form.Item>
    </Col>
  )
}

const SliderItem = ({ formName, data }: any) => {
  const { t } = useTranslation()
  return (
    <Row>
      <Col span={18}>
        <Form.Item
          name={formName}
          rules={[{ required: true, message: t('requiredItem') }]}
        >
          <InputNumber
            addonBefore={
              <Space>
                <span style={{ color: 'red' }}>*</span>
                <Icon name={`fa-light ${data.icon_name}`} />
                <Tooltip title={data.description}>
                  <span>{data.item}</span>
                </Tooltip>
              </Space>
            }
            addonAfter={data?.postfix}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
    </Row>
  )
}

const ChildrenItem = ({ data, orderRequestKey }: OrderItemProps) => {
  const { t } = useTranslation()
  const [selectedChildren, setSelectedChildren] = useState<IItem[]>(
    data?.children?.items?.map(item => item?.item) || []
  )
  const handleRadioChange = (e: RadioChangeEvent, name: number) => {
    const value = e.target.value
    const selectedChildItem = data.item.children.find(
      (child: IItem) => child.uuid === value
    )
    const newSelectedChildren = [...selectedChildren]
    newSelectedChildren[name] = selectedChildItem
    setSelectedChildren(newSelectedChildren)
  }
  return (
    <>
      {data.item.type === 'LIST' ? (
        <>
          <span>{data.item.item}</span>
          <Form.List name={[orderRequestKey, 'children', 'items']}>
            {(fields, { add, remove }) => (
              <>
                <Row gutter={[8, 8]} style={{ marginBottom: '10px' }}>
                  {fields.map(({ key, name }) => (
                    <Col key={key} span={24}>
                      <Row gutter={[16, 16]}>
                        <Form.Item name={[name, 'item', 'uuid']}>
                          <Radio.Group
                            optionType="button"
                            buttonStyle="solid"
                            onChange={e => handleRadioChange(e, name)}
                            style={{ marginRight: '20px' }}
                          >
                            {data.item.children.map((children: IItem) => (
                              <Radio key={children.uuid} value={children.uuid}>
                                {children.item}
                              </Radio>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                        {selectedChildren[name] && (
                          <Form.Item name={[name, 'current']}>
                            <InputNumber
                              addonAfter={selectedChildren[name]?.postfix}
                              style={{ marginRight: '20px' }}
                              min={selectedChildren[name]?.min}
                              max={selectedChildren[name]?.max}
                            />
                          </Form.Item>
                        )}
                        <IconButton
                          name="fa-light fa-trash"
                          onClick={() => remove(name)}
                          data-cy={`delete-item-${name}`}
                        />
                      </Row>
                    </Col>
                  ))}
                </Row>
                <Form.Item>
                  <Button
                    data-cy="add-item"
                    type="dashed"
                    onClick={() => add()}
                    block
                  >
                    {t('ADD_ITEM')}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </>
      ) : (
        <Form.Item
          name={[orderRequestKey, 'children', 'items', 0, 'item', 'uuid']}
          label={data.item.item}
          required={data.item.required}
        >
          <Select
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            options={data.item.children.map((item: IItem) => ({
              value: item.uuid,
              label: item.item,
            }))}
          />
        </Form.Item>
      )}
    </>
  )
}

export default FormItemsOrderRequest
