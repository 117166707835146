import { useState, useEffect } from 'react'
import { columns as ticketColums } from '@components/Ticket/Table'
import { columns as deviceColums } from '@components/Device/Table'
import { renderingTabFilter as renderingBackupTab } from '@components/Backup/Table'
import { columns as alertColums } from '@components/Monitoring/Table'
import { Alert, AlertProps, Divider, Tabs, Steps, Col, Row, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  contractColumns,
  overviewColumn
} from '@components/Device/Descriptions'
import ResponsiveTableCard from '@common/ResponsiveTableCard'
import { useUser } from '@contexts/UserContext'
import GraphTab from '@components/Device/GraphTab'
import { SyncOutlined } from '@ant-design/icons'
import { DeviceContext } from '@contexts/DeviceContext'
import { useGenericContext } from '@hooks/useGenericContext'
import { columns as companyColumn } from '@components/Company/Descriptions'
import { DeviceContextType, IDevice, IDeviceContract } from '@/types/IDevice'
import { api } from '@helpers/api'
import RenderPage from '@components/common/RenderPage'
import CustomDescriptions from '@components/common/CustomDescriptions'
import HardwareContent from '../Hardware/HardwareContent'
import { ISUser } from '@/types/IUser'
import { TabsProps } from 'antd/lib'
import { getAdditionalTabs } from '@helpers/getDevicetabs'

const { Step } = Steps;

const LoadingMessage = ({ message, ...props }: AlertProps) => {
  const { t } = useTranslation()
  return (
    <Alert
      message={t(String(message))}
      showIcon
      style={{ width: '100%' }}
      role=""
      {...props}
      icon={<SyncOutlined spin />}
    />
  )
}
const useDeviceContext = () =>
  useGenericContext<DeviceContextType>(DeviceContext)

type DeviceTableModels = 'ticket' | 'alert' | 'children'

const CompanyDescriptions = ({ ...props }) => {
  const { data } = useDeviceContext()
  const [loading, setLoading] = useState(true)
  const [company, setCompany] = useState(undefined)
  const { t } = useTranslation()
  useEffect(() => {
    api
      .get(`/company/${data?.company?.uuid}`)
      .then(e => setCompany(e.data))
      .finally(() => setLoading(false))
  }, [data.uuid])
  if (loading) return <LoadingMessage message={t('SEARCHINGCOMPANYINFOS')} />
  return (
    <>
      {company ? (
        <>
          <Divider>{t('COMPANYINFOS')}</Divider>
          <CustomDescriptions
            columns={companyColumn}
            dataSource={company}
            {...props}
          />
        </>
      ) : null}
    </>
  )
}

const CNDescriptions = ({ contracts }: { contracts: IDeviceContract[] | undefined }) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [doc, setDoc] = useState<IDeviceContract[]>([]);

  useEffect(() => {
    if (contracts) {
      const reversed = [...contracts].reverse();
      setDoc(reversed);
    }
  }, []);

  const nextStep = () => {
    setCurrentStep((prev) => (prev < doc.length - 1 ? prev + 1 : prev));
  };

  const prevStep = () => {
    setCurrentStep((prev) => (prev > 0 ? prev - 1 : prev));
  };

  return (
    <>
      <Divider data-cy="contract-title">{t('CNINFOS')}</Divider>
      <Steps current={currentStep} style={{ marginBottom: '20px' }}>
        {doc.map((_, index) => (
          <Step key={doc[index].uuid} />
        ))}
      </Steps>
      {doc[currentStep] && (
        <CustomDescriptions
          columns={contractColumns}
          dataSource={doc[currentStep]}
          column={1}
          data-cy="contracts-description"
        />
      )}
      <div style={{ marginTop: 24 }}>
        <Button onClick={prevStep} disabled={currentStep === 0} data-cy="previous-button">
          {t('PREVIOUS')}
        </Button>
        <Button
          onClick={nextStep}
          disabled={currentStep === doc.length - 1}
          style={{ marginLeft: 8 }}
          data-cy="near-button"
        >
          {t('NEAR')}
        </Button>
      </div>
    </>
  );
};

const SupportOverviewItems = () => {
  const { data } = useDeviceContext()
  return (
    <>
      <Col xl={{ span: 12 }} xs={24}>
        {data?.company?.uuid ? (
          <CompanyDescriptions column={1} />
        ) : null}
      </Col>
      {data?.contracts ? (
        <Col xl={12} xs={24}>
          <CNDescriptions contracts={data?.contracts} />
        </Col>
      ) : null}
    </>
  )
}
const DeviceTable = ({ model }: { model: DeviceTableModels }) => {
  const { data } = useGenericContext(DeviceContext)
  const types = {
    ticket: {
      url: `ticket?devices=${data.uuid}`,
      columns: ticketColums
    },
    children: {
      url: `device?parent=${data.uuid}`,
      columns: deviceColums
    },
    alert: {
      url: `device/${data.uuid}/alert`,
      columns: alertColums
    }
  }

  return <ResponsiveTableCard {...types[model]} />
}
const OverviewTab = () => {
  const { data } = useGenericContext(DeviceContext)
  const { user } = useUser()
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <CustomDescriptions columns={overviewColumn} dataSource={data} />
      </Col>
      {user.level > 1 ? <SupportOverviewItems /> : null}
    </Row>
  )
}

const HardwareDevice = () => {
  const { data } = useDeviceContext()
  return <HardwareContent uuid={data.uuid} />
}

const BackupTab = () => {
  const { data } = useDeviceContext()
  const renderBackupTab = renderingBackupTab(`?device=${data.uuid}`)
  return <Tabs items={renderBackupTab} />
}

const items = [
  {
    label: 'OVERVIEW',
    key: '1',
    children: <OverviewTab />
  },
  {
    label: 'TICKETS',
    key: '2',
    children: <DeviceTable model="ticket" />
  },
  {
    label: 'ALERTS',
    key: '3',
    children: <DeviceTable model="alert" />
  },
  {
    label: 'HARDWARE',
    key: '4',
    children: <HardwareDevice />
  },
  {
    label: 'CHILDREN',
    key: '5',
    children: <DeviceTable model="children" />
  },
  {
    label: 'BACKUP',
    key: '6',
    children: <BackupTab />
  },
  {
    label: 'GRAPHS',
    key: '7',
    children: <GraphTab />
  }
]

const returnTabs = (data: IDevice, user: ISUser) => {
  let baseTabs = items.slice(0, 4)

  if (data?.type === 'VM' || user.level === 1) {
    baseTabs = baseTabs.filter(tab => tab.key !== '4')
  }

  if (data?.type === 'VM' || data?.type === 'BAREMETAL') {
    const additionalTabs = getAdditionalTabs(data.type, items, user)
    return [
      ...baseTabs.filter(
        tab => !additionalTabs.some(addTab => addTab.key === tab.key)
      ),
      ...additionalTabs
    ]
  }
  return items.slice(0, 3)
}

const DeviceTabs = () => {
  const [tabs, setTabs] = useState<TabsProps['items']>()
  const { t } = useTranslation()
  const { data } = useDeviceContext()
  const { user } = useUser()

  useEffect(() => {
    setTabs(() => {
      return returnTabs(data, user)
    })
  }, [data.uuid])

  return (
    <RenderPage {...{ data: tabs, loading: !tabs }}>
      <Col span={24}>
        {tabs ? (
          <Tabs
            {...{ type: 'card', size: 'large', destroyInactiveTabPane: true }}
            items={tabs.map((tab: any) => ({ ...tab, label: t(tab.label) }))}
          />
        ) : null}
      </Col>
    </RenderPage>
  )
}

export default DeviceTabs
