import EditableAlias from '@components/common/EditableAlias'
import powered from '@constants/powered'
import { DeviceContext } from '@contexts/DeviceContext'
import { useGenericContext } from '@hooks/useGenericContext'
import { IDevice } from '@/types/IDevice'
import {
  List as AList,
  Button,
  ButtonProps,
  Card,
  Col,
  Divider,
  Row,
  Space,
  Tag,
  Typography,
  Tooltip
} from 'antd'
import { InfoCircleTwoTone } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import DeviceActions from '../DeviceActions'
import List from '@components/List'
import Icon from '@components/common/Icon'
import { DEVICETYPES } from '@constants/devicetypes'
import { sumDeviceSizes } from '@helpers/sumDeviceSizes'
import { UseTable } from '@/types/UseTable'
import DeviceInterfaces from '../DeviceInterfaces'
import { useDrawerContext } from '@contexts/DrawerContext'
import DeviceDrawer from '../DeviceDrawer'
import useWindowResize from '@hooks/useWindowResize'
import dayjs from 'dayjs'
import { useUser } from '@contexts/UserContext'

type DeviceProps = {
  data: IDevice
}

const { Meta } = Card
const { Title } = Typography
const DeviceCardTitle = () => {
  const { t } = useTranslation()
  const { data } = useGenericContext(DeviceContext)
  const { open } = useDrawerContext(`/device/${data?.uuid}`, data)
  const timeDelete = Math.max(
    0,
    48 - dayjs().diff(dayjs(data?.synced_at), 'hour')
  )
  const { user } = useUser()

  return (
    <Row align="middle" style={{ display: 'flex', gap: 10 }}>
      {data?.state && (
        <>
          <Tag color={`${powered[data?.state]}-inverse`}>{t(data?.state)}</Tag>
          {data?.state === 'InsufficientFunds' && (
            <Tooltip title={`${t('REMOVEDEVICE')} ${timeDelete} ${t('HOUR')}`}>
              <InfoCircleTwoTone style={{ fontSize: 20 }} twoToneColor="red" />
            </Tooltip>
          )}
        </>
      )}
      <Space align="start" style={{ flex: 1 }}>
        <Button type="link" style={{ padding: 0 }} onClick={open}>
          <Title style={{ margin: 0, lineHeight: 1 }} level={4}>
            {data?.name}
          </Title>
        </Button>
        {!user.roles?.includes('RO') && <EditableAlias name={data} />}
      </Space>
    </Row>
  )
}

const DeviceCardActions = () => {
  const { data } = useGenericContext(DeviceContext)
  return <DeviceActions data={data} type="default" />
}

const CustomButton = (props: ButtonProps) => (
  <Button shape="round" type="default" {...props} />
)

const DeviceCardFooter = () => {
  const { data } = useGenericContext(DeviceContext)
  return (
    <Space>
      {data?.group ? <CustomButton>{data?.group.name}</CustomButton> : null}
      {data?.dc ? <CustomButton> {data?.dc.shortname}</CustomButton> : null}
      {data?.company ? (
        <CustomButton> {data?.company.name}</CustomButton>
      ) : null}
    </Space>
  )
}

const DeviceSizeDescription = () => {
  const { data } = useGenericContext(DeviceContext)
  const { t } = useTranslation()

  let deviceSize = '0'
  if (data?.type === 'POD') {
    deviceSize = sumDeviceSizes(data)
  }

  if (!deviceSize || deviceSize === '0') {
    return null
  }

  return (
    <CustomButton icon={<Icon name="fa-light fa-memory" />}>
      {`${t('DISKS')}: ${deviceSize}`}
    </CustomButton>
  )
}

const DeviceInfos = () => {
  const { data } = useGenericContext(DeviceContext)
  const { t } = useTranslation()
  return (
    <Space wrap>
      {data?.os ? (
        <CustomButton icon={<Icon name={DEVICETYPES[data?.os.kind].icon} />}>
          {data?.os.name}
        </CustomButton>
      ) : null}
      {data?.cpu ? (
        <CustomButton icon={<Icon name="fa-light fa-microchip" />}>
          CPU: {data?.cpu}
        </CustomButton>
      ) : null}
      {data?.mem ? (
        <CustomButton icon={<Icon name="fa-light fa-memory" />}>
          {t('MEMORY')}: {data?.mem}GB
        </CustomButton>
      ) : null}
      <DeviceSizeDescription />
    </Space>
  )
}

const DeviceCardDescriptions = () => {
  const { data } = useGenericContext(DeviceContext)
  return (
    <>
      <DeviceInfos />
      <Col span={24} style={{ marginTop: 10 }}>
        <DeviceInterfaces interfaces={data?.interfaces} />
      </Col>
      <Divider />
      <DeviceCardFooter />
    </>
  )
}

const DeviceCard = ({ data }: DeviceProps) => {
  return (
    <AList.Item key={`${data.uuid}-${data.state}`}>
      <DeviceDrawer device={data}>
        <Card
          style={{ width: '100%', margin: '8px' }}
          title={<DeviceCardTitle />}
          extra={<DeviceCardActions />}
        >
          <Meta description={<DeviceCardDescriptions />} />
        </Card>
      </DeviceDrawer>
    </AList.Item>
  )
}

const handleDeviceColumns = (width: number) => {
  if (width < 768) return 1
  if (width < 1280) return 2
  return 3
}

const DeviceList = (props: UseTable<any, IDevice>) => {
  const { width } = useWindowResize()
  return (
    <List
      {...props}
      dataSource={props.data.data}
      grid={{ gutter: 16, column: handleDeviceColumns(width) }}
      renderItem={(device: IDevice) => <DeviceCard data={device} />}
    />
  )
}

export default DeviceList
