import { AddItemButtonProps, ProductFormListProps } from '@/types/IProduct'
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CloseOutlined
} from '@ant-design/icons'
import HiddenFormItem from '@components/common/HiddenFormItem'
import InputWithForm from '@components/common/InputWithForm'
import { SearchSelectWithForm } from '@components/common/SearchSelectWithForm'
import { PRODUCTTYPES } from '@constants/producttypes'
import { api } from '@helpers/api'
import { getGenericList } from '@helpers/getGenericList'
import { mapCleanFormatFilter } from '@helpers/MapCleanFormatFilter'
import useSearchSelect from '@hooks/useSearchSelect'
import { IProduct } from '@/types/IOrder'
import {
  Button,
  Col,
  Form, FormItemProps, InputNumber,
  Row,
  Space,
  Switch
} from 'antd'
import Card from 'antd/es/card/Card'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { NamePath } from 'antd/es/form/interface'
import { DefaultOptionType } from 'antd/es/select'
import { Select } from 'antd/lib'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const ProductRequiredCheckbox = ({ name }: { name: number }) => {
  const { t } = useTranslation()
  return (
    <Col span={3}>
      <Form.Item
        initialValue={true}
        rules={[{ required: true }]}
        name={[name, 'required']}
        label={t('REQUIRED')}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    </Col>
  )
}

export const ProductPriceInput = ({ name }: { name: NamePath }) => {
  const { t } = useTranslation()
  return (
    <Col span={3}>
      <Form.Item name={name} label={t('PRICE')} rules={[{ required: true }]}>
        <InputNumber min="0" step="0.01" />
      </Form.Item>
    </Col>
  )
}

export const ProductPreandPostFixInputs = ({ name }: { name: number }) => {
  const { t } = useTranslation()
  return (
    <>
      {['prefix', 'postfix'].map(item => (
        <Col span={4} key={item}>
          <InputWithForm
            required={item === 'postfix'}
            name={[name, item]}
            label={t(item.toUpperCase())}
          />
        </Col>
      ))}
    </>
  )
}

export const ProductOverrideInput = ({ name }: { name: number }) => {
  const { t } = useTranslation()
  return (
    <Col span={6}>
      <InputWithForm
        required={true}
        name={[name, 'override_name']}
        label={t('OVERRIDENAME')}
      />
    </Col>
  )
}

export const AddItemButton = ({
  add,
  span,
  path,
  ...props
}: AddItemButtonProps) => {
  const { t } = useTranslation()
  return (
    <Col
      span={span ?? 24}
      style={{
        margin: '16px 0 4px',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const item = path ? getFieldValue(path) : null
          return (
            <Button
              data-cy="add-item-button"
              onClick={() => add()}
              block
              type="primary"
              {...props}
              disabled={item?.type === 'ITEM'}
            >
              {t('NEWITEM')}
            </Button>
          )
        }}
      </Form.Item>
    </Col>
  )
}

const getDCS = async (search = ''): Promise<DefaultOptionType[]> => {
  return await api
    .get(`/dc?search=${search}`)
    .then(e => mapCleanFormatFilter(e.data, 'uuid', 'name'))
}

export const ProductItemInput = (props: FormItemProps) => {
  const { t } = useTranslation()
  return (
    <Col span={4}>
      <InputWithForm
        {...props}
        rules={[{ required: true }]}
        label={t('ITEM')}
      />
    </Col>
  )
}

export const ProductTypeSelect = ({
  name,
  path
}: {
  name: any
  path: NamePath
}) => {
  const { t } = useTranslation()
  const rule = [
    'REMOVEALLSUBITEMSTOSELECTTHISOPTION',
    'THEOPTIONITEMDONTALLOWSUBITEMS'
  ]
  const childrenObserver = Form.useWatch(path)
  const defaultOptions = PRODUCTTYPES.map(e => ({
    label: t(e),
    value: e,
    key: e,
    children: e
  }))
  const form = Form.useFormInstance()

  const [options, setOptions] = useState<Array<any>>(defaultOptions)
  useEffect(() => {
    const item = form.getFieldValue(path)
    if (item?.children?.length > 0)
      return setOptions(prev =>
        prev.map(e => {
          return e.value === 'ITEM'
            ? { ...e, disabled: true, label: t('ITEM') + ' ' + t(rule[0]) }
            : e
        })
      )
    return setOptions(defaultOptions)
  }, [childrenObserver])

  return (
    <Col span={4}>
      <Form.Item
        shouldUpdate
        name={[name, 'type']}
        rules={[{ required: true }]}
        label={t('TYPE')}
        tooltip={t(rule[1])}
      >
        <Select
          options={options}
          style={{ width: '100%' }}
          data-cy={[...path, 'type'].join('_')}
        />
      </Form.Item>
    </Col>
  )
}

export const FormItemActions = ({
  actions,
  index,
  field,
  path
}: ProductFormListProps) => {
  const form = useFormInstance()
  const values = form.getFieldsValue()
  const item = form.getFieldValue(path)
  const onClose = () => {
    if (item?.uuid) api.delete(`/product/${values.uuid}/item/${item.uuid}`)
    actions.remove(field.name)
  }
  return (
    <Space>
      <HiddenFormItem name="uuid" />
      <CaretUpOutlined onClick={() => actions.move(index, index - 1)} />
      <CaretDownOutlined onClick={() => actions.move(index, index + 1)} />
      <CloseOutlined onClick={onClose} data-cy={`delete-item-${item.uuid}`} />
    </Space>
  )
}

export const DCSelect = () => {
  const props = useSearchSelect<DefaultOptionType[], any>({
    func: getDCS
  })
  return <SearchSelectWithForm {...props} name="dc" label="DC" span={8} />
}

export const DependentOnSelect = ({
  name,
  path
}: {
  name: string
  path: NamePath
}) => {
  const { t } = useTranslation()
  const form = Form.useFormInstance()
  const items = form.getFieldValue('items')
  const defaultOptions = items.map(e => ({
    label: e.item,
    value: e.uuid,
    key: e.uuid,
  }))
  return (
    <Col span={6}>
      <Form.Item
        name={[name, 'dependent']}
        label={t('DEPENDENT_ON')}
      >
        <Select
          options={defaultOptions}
          style={{ width: '100%' }}
          data-cy={[...path, 'dependent'].join('_')}
          allowClear
        />
      </Form.Item>
    </Col>
  )
}

export interface ProductFormProps {
  action: 'create' | 'edit'
}

const { Option } = Select;

const useProductSelect = () => {
  const { options, ...props } = useSearchSelect({
    func: async (search = '') => await getGenericList('/product', search)
  })
  return { options, ...props }
}

const ProductSelect = ({ name }: { name: (string | number)[] }) => {
  const { options, ...props } = useProductSelect();
  const { t } = useTranslation();

  const form = Form.useFormInstance()
  const selectedUuid = form.getFieldsValue(['uuid'])?.uuid;

  return (
    <SearchSelectWithForm
      name={name}
      {...{ ...props }}
      label={t('RELATEDPRODUCT')}
      span={24}
    >
      {Array.isArray(options)
        ? options
          .filter(item => item.uuid !== selectedUuid)
          .map(item => (
            <Option
              {...{
                key: item.uuid,
                children: item.name,
                value: item.uuid,
                label: item.name
              }}
            />
          ))
        : null}
    </SearchSelectWithForm>
  );
};

export const RelatedProducts = ({ action }: ProductFormProps) => {
  const { t } = useTranslation()
  const form = Form.useFormInstance()
  let initial_related_products = form.getFieldsValue(['related_products'])

  function deleteRelationship(key: number, initial_related_products: IProduct) {
    const values = form.getFieldsValue(['related_products', 'uuid']);
    const uuidValues = values.related_products
      .filter((product: any) => product != null)
      .map((product: any) => product.uuid);

    const productToDelete = Array.isArray(initial_related_products.related_products)
      ? initial_related_products.related_products.find(
        (product) => product.uuid === uuidValues[key]
      )
      : null;

    if (productToDelete) {
      api.delete(`/product/${values.uuid}/related/${productToDelete.uuid}`).then(() => {
        initial_related_products = initial_related_products.filter(
          (product) => product.uuid !== productToDelete.uuid
        );
      });
    }
  }

  return (
    <Col span={24}>
      <Form.List name="related_products">
        {(fields, { add, remove }) => (
          <Row gutter={[8, 8]} style={{ marginBottom: '10px' }}>
            {fields.map((field) => (
              <Col span={6} key={field.key}>
                <Card
                  size="small"
                  title={`${t('CHILDRENPRODUCT')} ${field.name + 1}`}
                  key={field.key}
                  extra={
                    <CloseOutlined
                      onClick={() => {
                        if (action === 'edit') {
                          deleteRelationship(field.key, initial_related_products)
                        }
                        remove(field.name);
                      }}
                    />
                  }
                >
                  <ProductSelect name={[field.name, 'uuid']} />
                  <Form.Item name={[field.name, 'max']} label={t('MAX')} rules={[{ required: true }]}>
                    <InputNumber min="0" step="1" />
                  </Form.Item>
                </Card>
              </Col>
            ))}
            <Col span={24}>
              <Button data-cy="add-relation" type="dashed" onClick={() => add()} block>
                + {t('RELATEDPRODUCT')}
              </Button>
            </Col>
          </Row>
        )}
      </Form.List>
    </Col>
  )
}
