import InputWithForm from '@components/common/InputWithForm'
import { SelectInput } from '@components/common/SelectInput'
import { DCSelect, ProductFormProps, RelatedProducts } from '@components/Products/ProductFormItems'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import FormList from '../FormList'

const ProductForm = ({ action }: ProductFormProps) => {
  const { t } = useTranslation()

  return (
    <Row>
      <Col span={24}>
        <Row gutter={[8, 8]} style={{ width: '100%' }}>
          <Col span={8}>
            <InputWithForm required name="name" label={t('NAME')} />
          </Col>
          <DCSelect />
          <SelectInput
            inputName="type"
            span={6}
            label={'DEVICETYPE'}
            constant={'DEVICE_TYPES'}
            required
          />
          <RelatedProducts action={action} />
          <FormList />
        </Row>
      </Col>
    </Row>
  )
}

export default ProductForm
