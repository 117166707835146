const renderValue = (value) => {
  if (typeof value === 'object' && value !== null) {
    return JSON.stringify(value)
  }
  return String(value)
}

const renderDetails = (value, parentKey) => {
  if (typeof value === 'object' && value !== null) {
    return Object.entries(value).map(([subKey, subValue]) => (
      <div key={`${parentKey}-${subKey}`} style={{ marginLeft: '10px' }}>
        {subKey}: {renderValue(subValue)}
      </div>
    ))
  }
  return <span style={{ marginLeft: '10px' }}>{renderValue(value)}</span>
}

const DeviceAccess = ({ accessObj }: { accessObj?: object }) => {
  if (typeof accessObj !== 'object' || accessObj === null) {
    return null
  }

  return Object.entries(accessObj).map(([key, value]) => (
    <div key={key} style={{ marginBottom: 10 }}>
      <strong>{key}:</strong>
      {renderDetails(value, key)}
    </div>
  ))
}

export default DeviceAccess
